canvas.AudioPlayerCanvas {
    height: 70px;
    width: 100%;
}

button.selectedSpeedButton {
    background-color: white;
    color: #16b;
    font-weight: 700;
    border: 0px;
    padding-top: 1px;
    padding-left: 2px;
    padding-right: 2px;
    padding-botton: 1px;
}

button.speedButton {
    background-color: white;
    border: 0px;
    padding-top: 1px;
    padding-left: 2px;
    padding-right: 2px;
    padding-botton: 1px;
    font-weight: 400;
}

button.playButton {
    width: 80px;
    background-color: white;
}