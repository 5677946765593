.contact-timeline::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

.contact-timeline::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
