.chat-medium {
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    border-style: solid;
    border-width: thin;
    border-radius: 5px;
    padding: 4px;
    overflow-y: auto;
    max-height: 450px;
}

.customer-message {
    background-color: #eaeded;
    width: fit-content;
    border-radius: 5px;
    margin: 4px 4px 0px;
    padding: 4px;
}

.amazon-message {
    color: white;
    background-color: #0073bb;
    width: fit-content;
    border-radius: 5px;
    margin: 4px 4px 0px;
    padding: 4px;
    align-self: flex-end;
}

.customer-message-timestamp {
    margin-left: 4px;
    margin-bottom: 10px;
}

.amazon-message-timestamp {
    text-align: right;
    align-self: flex-end;
    margin-bottom: 10px
}

.date-timezone-timestamp{
    text-align: center;
}

div.amazon-message a {
    color: orange;
}

.amazon-message-name {
    text-align: right;
}