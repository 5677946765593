h2.what-is-papyrus {
    font-weight: normal;
}

h4.contact-details-header {
    color: #708090;
    font-size: 1.17em;
    font-weight: bold;
}

div.contact-details-header {
    color: #708090;
    font-size: 1.17em;
    font-weight: bold;
}

div.contact-details-subheader {
    color: #708090;
    font-weight: bold;
}

h4.contact-details-subheader {
    color: #708090;
    font-weight: bold;
}

span.timezone-info {
    color: grey;
    font-size: smaller;
    float: right;
}

.form-field-label {
    color: var(--awsui-color-text-form-secondary, #687078);
    font-size: 1.2rem;
}

/*
  CSC default font size is 12 px.
  Polaris scales all fonts relative from a html font-size of 10px and results in 14px fonts.
  By changing the font size for html to 10 * (12/14) px the Polaris resulting fonts are 12px in size.
  This is not 'Polaris' supported and may break in the future.
*/
html {
    font-size: 8.5714px;
}